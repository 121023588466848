import React from "react";
import Typed from 'typed.js';

import { Box, Text, Heading } from "theme-ui";
import { Row, Column } from "@carbonplan/components";
import { Link } from "gatsby";
import { Arrow } from "@carbonplan/icons";
import ArrowButton from "../arrowbutton";

var Strings = ["CS @ Illinois.", "Pratham Inamdar."]

class Title extends React.Component {
  componentDidMount() {
    const options = {
        strings: Strings,
      typeSpeed: 30,
      backSpeed: 10
    };
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <div className="wrap">
        <div className="type-wrap">
          <span
            ref={(el) => { this.el = el; }}
          />
        </div>
      </div>
    );
  }
}

const Intro = () => (
  <Box
    mb={[5, 8]}
  >
    <Box
      sx={{
        position: "absolute",
        left: "0px",
        // bg: "muted",
        width: "100%",
        height: ["0vh", "10vh", "10vh", "10vh"],
      }}
    />
    <Box
      sx={{
        height: ["0vh", "10vh", "10vh", "10vh"],
      }}
    />
    <Row sx={{ mt: [2, 2] }}>
      <Column start={[1, 1, 2, 2]} width={[4, 9, 9, 9]}>
        <Heading
          sx={{
            letterSpacing: "heading",
            fontSize: [7, 7, 7, 7],
            color: "red",
          }}
        >
          {<Title />}
        </Heading>
      </Column>

      <Column start={[1, 1, 2, 2]} width={[5, 4, 5, 5]}>
        <Box
          sx={{
            pt: [2, 2],
            fontSize: [3, 3, 4, 5],
          }}
        >
          <Text>
            I'm a Computer Science Student at the University of Illinois Urbana-Champaign.
            Feel free to shoot me an email if you'd like to chat, or for any interests or inquiries!
          </Text>

          <Box mt={[4]}>
            <Link to={"/blog"}>
              <ArrowButton sx={{ color: "grey" }} size="md" > Blog </ArrowButton>
            </Link>
          </Box>
        </Box>
      </Column>

      <Column start={[6, 7, 9, 9]} width={[1]} sx={{ position: "relative" }}>
        <Arrow
          sx={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            transform: "rotate(135deg)",
            fill: "red",
          }}
        />
      </Column>
    </Row>
  </Box>
);

export default Intro;
