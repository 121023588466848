import React from "react";
import { Box, Text, Heading } from "theme-ui";
import { Row, Column } from "@carbonplan/components";

const CourseList = [
  "Calculus I, II & III",
  "Linear Algebra & Computational Applications",
  "Physics: Mechanics, Electricity & Magnetism",
  "Advertising Research Methods",
  "Micro/Macro Economics",
  "Inter Microeconomic Theory",
  "Economic Statistics I & II",
  "Introduction to Computer Science I & II",
  "Discrete Structures",
  "Mobile Application Development",
  "Data Structures",
  "Statistics & Probability",
  "System Programming",
  "Computer Architecture",
  "Numerical Methods",
  "Software Design Lab",
  "Intro to Algorithms",
  "Applied Machine Learning",
];

const CourseWork = () => (
  <Box id="coursework" mt={[3, 2, 6, 6]} mb={[3, 3, 6, 6]}>
    <Row mb={[1, 2]}>
      <Column start={[1, 1, 2, 2]} width={[3]}>
        <Heading
          sx={{
            fontSize: [4, 5],
            color: "red",
          }}
        >
          Course Work
        </Heading>
      </Column>
    </Row>

    <Row>
      <Column start={[1, 1, 2, 2]} width={[6, 7, 8, 8]}>
        <Text
          sx={{
            color: "secondary",
            fontSize: [2, 3, 3, 4],
            fontFamily: "faux",
            letterSpacing: "faux",
            textTransform: "uppercase",
          }}
        >
          {CourseList.join(" ● ")}
        </Text>
      </Column>
    </Row>
  </Box>
);

export default CourseWork;